import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import DataListInput from './DataListInput';
import { DataCell, DataRow, DataTable, DataTableButton } from './DataTable';
import * as Roles from 'constants/Roles';
import UIControllerImageLoaderPage from './UIControllerImageLoader';
import UIControllerRunningExecutionsPage from './UIControllerRunningExecutions';
import { store } from '../../AppContext';
import { enqueueNotification } from '../../store/Notifications';
import { saveAs } from 'file-saver';
import { CardContent } from '@mui/material';
import * as SourceSystem from 'constants/SourceSystem';
import { ConversionTypeModel } from '../../client/ApiClient';

interface IUIControllerPageState {
    controller: Clients.UIControllerModel | null;
    userList: Clients.UserModel[] | null;
    selected: string;
    conversionTypes: Clients.ConversionTypeModel[] | null;
}

class UIControllerPage extends React.Component<RouteComponentProps, IUIControllerPageState>{
    private readonly client: Clients.ConversionClient = new Clients.ConversionClient();
    private readonly users: Clients.UserClient = new Clients.UserClient();
    private conversionTypeClient: Clients.ConversionTypeClient = new Clients.ConversionTypeClient();

    constructor(props: Readonly<RouteComponentProps>) {
        super(props)
        this.state = {
            controller: null,
            selected: '',
            userList: null,
            conversionTypes: null,
        }
    }

    componentDidMount() {
        this.showUsers()
        this.getConversionTypes()
        this.client.getUIController()
            .then(response => {
                this.setState({
                    controller: response!
                })
            })
            .catch(error => { console.log(error) });
    }

    update = <T extends keyof Clients.UIControllerModel>(type: T) => {
        const controller = Object.assign({}, this.state.controller)
        if (controller) {
            let message = '';
            if (type === 'addToMain') {
                controller.addToMain = !controller.addToMain
                message = controller.addToMain ? 'Add To Main - Merge On' : 'Add To Main - Merge Off'
            } else if (type === 'copyMapMerge') {
                controller.copyMapMerge = !controller.copyMapMerge
                message = controller.copyMapMerge ? 'Merge Copy Map On' : 'Merge Copy Map Off'
            } else if (type === 'addToMainEpic') {
                controller.addToMainEpic = !controller.addToMainEpic
                message = controller.addToMainEpic ? 'Add To Main - Epic On' : 'Add To Main - Epic Off'
            }
            this.setState({ controller }, () => {
                this.client.updateUIController(this.state.controller)
                    .then(() => store.dispatch(enqueueNotification({ type: 'success', message: message })))
                    .catch(error => {
                        console.log(error)
                    });
            })
        }
    }

    databaseReport = () => {
        this.client.databaseReports()
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => {
                console.log(error)
            });
    }

    sendDatabaseReport = () => {
        this.client.sendDatabaseReports()
            .then(() => store.dispatch(enqueueNotification({ type: 'success', message: 'Email Sent' })))
            .catch(error => {
                console.log(error)
                if (error.Diagnostics.Message) {
                    store.dispatch(enqueueNotification({ type: 'error', message: error.Diagnostics.Message }))
                }
                else {
                    store.dispatch(enqueueNotification({ type: 'error', message: 'Email Failed' }))
                }
            });
    }

    showUsers = () => {
        this.users.getAll()
            .then(response => {
                if (response) this.setState({ userList: response })
            })
            .catch(err => console.log(err))
    }

    getConversionTypes = () => {
        this.conversionTypeClient.getAll()
            .then(response => {
                if (response !== null) {
                    if (response.length < 1) throw new Error('Source System Types list should not be empty.');
                    this.setState({
                        conversionTypes: response
                    })
                }
            })
            .catch(error => { });
    }

    handleChange = (e: any) => {
        const value = e.value;
        this.setState({ selected: value },
            () => {
                if (value !== '') {
                    this.editList(value, true)
                }
            })
    }

    editList = (id: string, add: boolean) => {
        this.users.editEmailList(id, add)
            .then(() => this.showUsers())
            .catch(err => console.log(err))
    }

    emailList = (): any[] => {
        const arr = [];
        const userList = this.state.userList && this.state.userList.filter(u => !u.emailList && (u.role!.id === Roles.administrationRoleId || u.role!.id === Roles.developerAdministrationRoleId));
        if (userList) {
            for (const [, user] of userList!.entries()) {
                arr.push(<option
                    key={user.id}
                    data-value={user.id}
                >
                    {`${user.firstName} ${user.lastName}: ${user.emailAddress}`}
                </option>)
            }
        }
        return arr;

    }

    conversionTypeToggle = (type: ConversionTypeModel) => {
        const active = !type.isActive
        const text = active ? 'On' : 'Off'

        const conversionTypes = this.state.conversionTypes!
        for (let i = 0; i < conversionTypes.length; i++) {
            if (conversionTypes[i].id === type.id) {
                conversionTypes[i].isActive = active
            }
        }

        this.conversionTypeClient.updateConversionTypeActive(type.id!, active)
            .then(() => this.setState({ conversionTypes }, () => {
                store.dispatch(enqueueNotification({ type: 'success', message: `${type.description} - ${text} ` }))
            }))
            .catch(error => {
                console.log(error)
            });
    }

    public render() {
        return (
            <>
                <Container>
                    <Row>
                        <Col xs="2">
                            <Card style={{
                                width: '16rem',
                                margin: '1%'
                            }}>
                                <CardBody>
                                    <CardTitle tag="h4">
                                        UI Controller
                                    </CardTitle>
                                    <CardText>
                                        <label className="switch"><input type="checkbox" checked={(this.state.controller && this.state.controller!.addToMain) || false} onChange={() => this.update('addToMain')} /> <span className="slider round"></span></label> Add To Main - Merge
                                    </CardText>
                                    <CardText>
                                        <label className="switch"><input type="checkbox" checked={(this.state.controller && this.state.controller!.addToMainEpic) || false} onChange={() => this.update('addToMainEpic')} /> <span className="slider round"></span></label> Add To Main - Epic
                                    </CardText>
                                    <CardText>
                                        <label className="switch">
                                            <input type="checkbox" checked={(this.state.controller && this.state.controller!.copyMapMerge) || false} onChange={() => this.update('copyMapMerge')} /> <span className="slider round"></span></label>
                                        Merge - Copy Map
                                    </CardText>
                                </CardBody>
                            </Card>
                            <Card style={{
                                width: '16rem',
                                margin: '1%'
                            }}>
                                <CardBody>
                                    <CardTitle tag="h4">
                                        Conversion Type Controller
                                    </CardTitle>
                                    {this.state.conversionTypes && this.state.conversionTypes!.map(item => {
                                        return (
                                            <CardText>
                                                <label className="switch">
                                                    <input type="checkbox"
                                                        id={item.id}
                                                        checked={(item.isActive) || false}
                                                        onChange={(e) => this.conversionTypeToggle(item)}
                                                        disabled={item.id!.toUpperCase() === SourceSystem.miniToAMS360Id || item.id!.toUpperCase() === SourceSystem.tamToAMS360Id} />
                                                    <span className="slider round"></span>
                                                </label> {item.description}
                                            </CardText>)
                                    })}
                                </CardBody>
                            </Card>
                            <UIControllerImageLoaderPage />
                        </Col>
                        <Col xs="9">
                            <Card style={{
                                width: '57rem',
                                height: '28rem',
                                margin: '0 10%',
                            }}>
                                <CardBody>
                                    <CardTitle tag="h3">
                                        Database Report
                                    </CardTitle>
                                    <CardText>
                                        <Button
                                            className='load-button'
                                            onClick={() => this.databaseReport()}
                                        > {'Download Database Report'} </Button>
                                    </CardText>
                                    <CardText>
                                        <Button
                                            className='load-button'
                                            onClick={() => this.sendDatabaseReport()}
                                        > {'Send Database Report'} </Button>
                                    </CardText>
                                    <CardContent>
                                        <DataListInput
                                            id={'emailList'}
                                            name={'emailList'}
                                            value={this.state.selected}
                                            onValueChange={event => this.handleChange(event)}
                                            placeholder={'Select Users'}
                                        >
                                            <option data-value='' />
                                            {this.emailList()}
                                        </DataListInput>
                                        <DataTable hover >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        First Name
                                                    </th>
                                                    <th>
                                                        Last Name
                                                    </th>
                                                    <th>
                                                        Email
                                                    </th>
                                                    <th>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="userTable">
                                                {this.state.userList && this.state.userList!
                                                    .filter(u => u.emailList)
                                                    .map(u => {
                                                        return (<DataRow id={u.id!.toString()} key={u!.id}>
                                                            <DataCell>
                                                                {u.firstName}
                                                            </DataCell>
                                                            <DataCell>
                                                                {u.lastName}
                                                            </DataCell>
                                                            <DataCell>
                                                                {u.emailAddress}
                                                            </DataCell>
                                                            <DataCell>
                                                                <DataTableButton
                                                                    icon='close'
                                                                    id={"removeEmailList"}
                                                                    title={"Remove: " + u.firstName + " " + u.lastName}
                                                                    action={() => this.editList(u.id!, false)} />
                                                            </DataCell>
                                                        </DataRow>)
                                                    })}
                                            </tbody>
                                        </DataTable>
                                    </CardContent>
                                </CardBody>
                            </Card>
                            <UIControllerRunningExecutionsPage />
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Container >
            </>
        )
    }
}

export default withRouter(UIControllerPage)