import * as Clients from 'client/ApiClient';
import ReportDynamicDataTable from 'components/basic/ReportDynamicDataTable';
import * as Controls from 'components/basic/Form';
import Toolbar from 'components/basic/Toolbar';
import { reportsList, miniReportsList, mergeReportsList, ndtReportsList } from 'components/conversion/reports/ReportsList';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { RangePagination } from 'components/basic/Pagination';
import { saveAs } from 'file-saver';
import './Report.scss';
import * as SourceSystem from 'constants/SourceSystem';


interface IConversionReportsPageProps extends RouteComponentProps<{
    conversionId: string;
    reportId: string;
}> {
}

interface IReportViewPageState {
    records: Clients.DynamicEntityListModel;
    pageRecords: Clients.DynamicEntityListModel;
    conversion: Clients.ConversionModel | null;
    startDate: Date;
    endDate: Date;
    pageSize: number;
    pageNumber: number;
}

class ReportViewPage extends React.Component<IConversionReportsPageProps, IReportViewPageState>{
    private readonly client = new Clients.ConversionReportsClient();
    private readonly conversionClient = new Clients.ConversionClient();

    constructor(props: IConversionReportsPageProps) {
        super(props);
        let start = new Date();
        start.setMonth(start.getMonth() - 1);

        this.state = {
            records: new Clients.DynamicEntityListModel(),
            pageRecords: new Clients.DynamicEntityListModel(),
            conversion: null,
            startDate: start,
            endDate: new Date(),
            pageSize: 50,
            pageNumber: 0,
        }
    }

    componentDidMount() {
        this.runReport();
        this.conversionClient.get(this.props.match.params.conversionId)
            .then(response => {
                this.setState({ conversion: response! })
            })
            .catch(() => { });
    }

    componentDidUpdate(prevProps: IConversionReportsPageProps, prevState: IReportViewPageState) {
        if (this.state.pageNumber !== prevState.pageNumber
            || this.state.pageSize !== prevState.pageSize
        ) {
            this.recordsPage();
        }
    }

    runReport = () => {
        this.client.run(this.props.match.params.conversionId, this.props.match.params.reportId, this.state.startDate, this.state.endDate)
            .then(response => {
                if (response) {
                    let records = new Clients.DynamicEntityListModel();
                    records.entities = response.entities
                    records.schema = response.schema;
                    records.count = response.entities!.length

                    this.setState({
                        records
                    }, () => { this.recordsPage(); })
                }
            })
            .catch(error => { console.log(error) });
    }

    recordsPage = () => {
        let pageRecords: any = this.state.records
        const entities = this.state.records.entities && this.state.records.entities.slice(this.state.pageNumber * this.state.pageSize, (this.state.pageNumber + 1) * this.state.pageSize);
        pageRecords = { ...pageRecords, entities }
        this.setState({ pageRecords });
    }

    exportToExcel = () => {
        this.client.exportToExcel(this.props.match.params.conversionId, this.props.match.params.reportId, this.state.startDate, this.state.endDate)
            .then(response => saveAs(response!.data, response!.fileName))
            .catch(error => { console.log(error) });
    }

    public render() {
        const conversion = this.state.conversion;
        const conversionTypeId = conversion! ? conversion!.conversionTypeId!.toUpperCase() : '';
        const reportId = this.props.match.params.reportId;
        const report = conversionTypeId === SourceSystem.ams360ToAMS360Id ? mergeReportsList.find(x => x.id === reportId)! :
            conversionTypeId === SourceSystem.ndtAms360ToAMS360Id ? ndtReportsList.find(x => x.id === reportId)! :
                conversionTypeId === SourceSystem.miniToAMS360Id ? miniReportsList.find(x => x.id === reportId)! :
                    reportsList.find(x => x.id === reportId)!;
        return (
            report ?
            <>
                <h3 className='mapping-body-header'>{report.name}</h3>
                <Toolbar>
                    {report.hasDateRange &&
                        <>
                            <div className='date-label'>Start Date:</div>
                            <Controls.TrackedDateDataControl
                                id='startDate'
                                tag={DatePicker}
                                value={this.state.startDate!.toString()}
                                onCommit={(id, value) => {
                                    this.setState({
                                        startDate: new Date(value!)
                                    }, () =>
                                        this.runReport()
                                    )
                                }}
                            />
                            <div className='date-label'>End Date:</div>
                            <Controls.TrackedDateDataControl
                                id='endDate'
                                tag={DatePicker}
                                value={this.state.endDate!.toString()}
                                onCommit={(id, value) => {
                                    this.setState({
                                        endDate: new Date(value!)
                                    }, () =>
                                        this.runReport()
                                    )
                                }}
                            />
                        </>
                    }
                    <Button className='load-button' onClick={this.exportToExcel}>
                        Export to Excel
                    </Button>
                </Toolbar>
                {this.state.pageRecords &&
                    <ReportDynamicDataTable
                        records={this.state.pageRecords} />
                }
                <br />
                <RangePagination
                    pageNumber={this.state.pageNumber}
                    pageSize={this.state.pageSize}
                    recordsCount={this.state.records.count}
                    onChangeIndex={index => this.setState({ pageNumber: index })}
                />
                </> :
            <></>
        )
    }
}

export default withRouter(ReportViewPage)