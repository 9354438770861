export const reportsList = [
    {
        id: 'clientsConversion',
        name: 'Client Conversion Log',
        hasDateRange: false
    },
    {
        id: 'policiesConversion',
        name: 'Policy Conversion Log',
        hasDateRange: false
    },
    {
        id: 'linesOfBusinessConversion',
        name: 'Line of Business Conversion Log',
        hasDateRange: false
    },
    {
        id: 'employeesConversion',
        name: 'Employee Conversion Log',
        hasDateRange: false
    },
    {
        id: 'claimsConversion',
        name: 'Claim Conversion Log',
        hasDateRange: false
    },
    {
        id: 'imagesConversion',
        name: 'Image Conversion Log',
        hasDateRange: false
    },
    {
        id: 'missingImagesConversion',
        name: 'Missing & Compressed Images',
        hasDateRange: false
    },
    {
        id: 'worksmartClientImages',
        name: 'WorkSmart Client Images',
        hasDateRange: false
    },
    {
        id: 'inconsistentPackages',
        name: 'Inconsistent Packages',
        hasDataRange: false
    },
    {
        id: 'employeesMissingRoles',
        name: 'Employees Missing Roles',
        hasDataRange: false
    },
    {
        id: 'codeCounts',
        name: 'Code Counts Report',
        hasDateRange: false
    },
    {
        id: 'runtimeStatistics',
        name: 'Runtime Statistics',
        hasDateRange: false
    },
]


export const mergeReportsList = [
    {
        id: 'clientsConversion',
        name: 'Client Conversion Log',
        hasDateRange: false
    },
    {
        id: 'policiesConversion',
        name: 'Policy Conversion Log',
        hasDateRange: false
    },
    {
        id: 'linesOfBusinessConversion',
        name: 'Line of Business Conversion Log',
        hasDateRange: false
    },
    {
        id: 'employeesConversion',
        name: 'Employee Conversion Log',
        hasDateRange: false
    },
    {
        id: 'claimsConversion',
        name: 'Claim Conversion Log',
        hasDateRange: false
    },
    {
        id: 'codeCounts',
        name: 'Code Counts Report',
        hasDateRange: false
    },
    {
        id: 'runtimeStatistics',
        name: 'Runtime Statistics',
        hasDateRange: false
    },
]

export const ndtReportsList = [
    {
        id: 'clientsConversion',
        name: 'Client Conversion Log',
        hasDateRange: false
    },
    {
        id: 'policiesConversion',
        name: 'Policy Conversion Log',
        hasDateRange: false
    },
    {
        id: 'linesOfBusinessConversion',
        name: 'Line of Business Conversion Log',
        hasDateRange: false
    },
    {
        id: 'employeesConversion',
        name: 'Employee Conversion Log',
        hasDateRange: false
    },
    {
        id: 'claimsConversion',
        name: 'Claim Conversion Log',
        hasDateRange: false
    },
    {
        id: 'codeCounts',
        name: 'Code Counts Report',
        hasDateRange: false
    },
    {
        id: 'runtimeStatistics',
        name: 'Runtime Statistics',
        hasDateRange: false
    },
    {
        id: 'rollbackConversion',
        name: 'Rollback Conversion Log',
        hasDateRange: false
    },
]

export const miniReportsList = [
    {
        id: 'miniCustomersValidation',
        name: 'Customers Validation',
        hasDateRange: false
    },
    {
        id: 'miniCustomersConversion',
        name: 'Customers Post Conversion Log',
        hasDateRange: false
    },
    {
        id: 'miniPoliciesValidation',
        name: 'Policies Validation',
        hasDateRange: false
    },
    {
        id: 'miniPoliciesConversion',
        name: 'Policies Post Conversion Log',
        hasDateRange: false
    },
]