// The lowercased entity names as they appear in a URL.
export const toolTips = [
    {
        name: 'Earliest Policy Expiration Date',
        tooltip: ['Indicates the earliest expiration date a policy can have to be eligible for conversion.']
    },
    {
        name: 'Translate Division By',
        tooltip: ['Division is the primary business unit within an agency. An agency can have one or more divisions.',
            'An agency must contain at least one division and one department.  AMS360 comes with a default division and department.',
            'AMS360 requires a division on all client and policy records.  Records containing a null or blank division will default to Division One (Div1.)',
            ' You will complete your Division setups in AMS360 during the Set-Ups Training.  If you have questions please contact your Conversion Contact.',
            ' DO NOT change, or delete Divisions in your AMS360 Set-Ups system.  Divisions CAN be added in AMS360 if you need to map to additional Divisions.  Do not change Division short names or mark them Inactive until AFTER the converted data loads.  Doing so will alter the expected conversion results.']
    },
    {
        name: 'Translate Branch By',
        tooltip: ["Branch can be a second level of business unit in an agency. Each division can have one or more branches.",
            "Each Branch can have a separate address from the agency's address.",
            "Branches are NOT required in AMS360.  They are an optional feature that can be enabled by selecting the Branch Conversion Option.",
            "When branches are turned on, AMS360 requires a branch on all client and policy records.  Records containing a null or blank branch will default to a code that mirror's the Division code. Branch One (Brn1).",
            "You will complete your Branch setups in AMS360 during the Set-Ups Training.  If you have questions please contact your Conversion Contact.",
            "DO NOT change, or delete Branches in your AMS360 Set-Ups system.  Branches CAN be added in AMS360 if you need to map to additional Branches.  Do not change Branch short names or mark them Inactive until AFTER the converted data loads.  Doing so will alter the expected conversion results.",
            "DO NOT add (turn on) Branches in your AMS360 Set-Ups system if they were not previously defined in the Portal Conversion Options.  Contact your Conversion Contact for assistance."]
    },

    {
        name: 'Translate Department By',
        tooltip: ["Departments can be used to further subdivide the agency structure. Each branch can have one or more departments.",
            "AMS360 Departments can be created from EPIC Department codes, or Line of Business codes.  Each department can have a separate address from the agency's address. These addresses can appear on various forms.",
            "AMS360 requires a Department on all client and policy records. Records containing a null or blank department will default to Department One (Dept1).",
            "You will complete your Department setups in AMS360 during the Set-Ups Training.  If you have questions please contact your Conversion Contact.",
            "DO NOT change, or delete Departments in your AMS360 Set-Ups system.  Departments CAN be added in AMS360 if you need to map to additional Departments. Do not change Department short names or mark them Inactive until AFTER the converted data loads.  Doing so will alter the expected conversion results."]
    },

    {
        name: 'Translate Group By',
        tooltip: ["Group is the lowest level of business unit structure. Each department can have one or more groups.",
            "Groups are NOT required in AMS360.  They are an optional feature that can be enabled by selecting the Group Conversion Option.",
            "When groups are turned on, AMS360 requires a group on all client and policy records.  Records containing a null or blank group will default to a code that mirror's the Division code. Group One (Grp1).",
            "You will complete your Group setups in AMS360 during the Set-Ups Training.  If you have questions please contact your Conversion Contact.",
            "DO NOT change, or delete Groups in your AMS360 Set-Ups system.  Groups CAN be added in AMS360 if you need to map to additional Groups.  Do not change Group short names or mark them Inactive until AFTER the converted data loads.  Doing so will alter the expected conversion results.",
            "DO NOT add (turn on) Group in your AMS360 Set-Ups system if they were not previously defined in the Portal Conversion Options.  Contact your Conversion Contact for assistance."]
    },

    {
        name: 'Convert Prospects',
        tooltip: ['This will designate if Prospects convert, or not.']
    },

    {
        name: 'Convert Cancelled Policies',
        tooltip: ['Indicates to convert policies with a policy status type code of cancelled.',
            'Policy status is the current state of the AMS360 policy.  The conversion programs are written to report each unique Applied policy status type code.  Policy transaction is the most recent action taken on the AMS360 policy.']
    },

    {
        name: 'Earliest Cancelled Policy Expiration Date',
        tooltip: ['Indicates a date to be used to drop all policies with a status type code of Cancelled and a policy effective date earlier than the desired drop date.  If opting to not convert Cancelled Policies this date will not impact the conversion.']
    },

    {
        name: 'Convert Client Images to AMS360',
        tooltip: ['Indicates if Client attachments will convert to AMS360 Customers, or not.  If using Worksmart, then this option should be set to no.  Speak with your Conversion Analyst for more information.']
    },
    {
        name: 'Convert Images',
        tooltip: ['Turning this option on will include Images (Attachments and other documents) during the Merge Conversion.']
    },
    {
        name: 'Convert Client Images Date',
        tooltip: ['Indicates the later of upload or update date as a cut - off for attachments.  If opting to not convert Client Images to AMS360 this date will not impact the conversion.']
    },

    {
        name: 'Convert Closed Activities',
        tooltip: ["Indicates if closed Activity items should convert, or not."]
    },

    {
        name: 'Earliest Closed Activity Date',
        tooltip: ['Indicates the cutoff date for converting closed Activity items, based on the Follow Up Date/Due Date.']
    },

    {
        name: 'Convert Open Activities',
        tooltip: ['Indicates if open Activity items should convert to open Suspense items in AMS360, or not.']
    },

    {
        name: 'Earliest Open Activity Date',
        tooltip: ['Indicates the cutoff date for converting open Activity items, based on the Follow Up Date/Due Date.']
    },

    {
        name: 'Convert Closed Claims',
        tooltip: ['Should Closed Claims be converted?',
            'Claims must link to a policy to convert.',
            'Claims convert attached to the appropriate policy term when the policy term exists in the Applied policy list screen.',
            'Translate the Claim types using the Kind of Loss translations in SmartMap.']
    },

    {
        name: 'Earliest Closed Claim Date',
        tooltip: ['Indicates the cutoff date for converting closed claims, based on the date they were closed.']
    },

    {
        name: 'Convert Open Claims',
        tooltip: ['Should Open Claims be converted?',
            'Claims must link to a policy to convert.',
            'Claims convert attached to the appropriate policy term when the policy term exists in the Applied policy list screen.',
            'Translate the Claim types using the Kind of Loss translations in SmartMap.']
    },

    {
        name: 'Ignore Policy Expiration Date for Claim policies',
        tooltip: [`Indicates if Policies with Claims will ignore the "Earliest Policy Expiration Date" conversion option.  If set to Yes, a Policy and Claim will convert regardless if the policy expiration date is prior to the "Earliest Policy Expiration Date" that has been set.  Other methods of dropping Policies will not take this option into consideration.`]
    },

    {
        name: 'Client Certificate Holders',
        tooltip: ['Indicates if Client Certificate Holders, including Master Certificate Holders, will convert to AMS360 Customer Certificate Holders setup, or not.']
    },

    {
        name: 'Drop Clients without Policies (Default to "No")',
        tooltip: ['Indicates if Clients without Policies will convert to AMS360, or not.']
    },

    {
        name: 'Create Billing for Policies',
        tooltip: [`Indicates if Policies will set their "How Billed" method to the designated value provided by that Policies' Parent Company.  If set to "No" then all Policies will have their "How Billed" method set to Not Billable.`]
    },

    {
        name: 'Use Company Specific Billing Options',
        tooltip: [`Should the company specific billing configurations be used or just the defaults at the agency level`]
    },

    {
        name: 'Billing Transaction Cutoff Date',
        tooltip: ['Indicates the cutoff date that a policy will either set its How Billed Method based on the Parent company designation, or to Not Billable, based on the effective date of the policy.']
    },

    {
        name: 'Convert Service Center Notation',
        tooltip: ['Indicates if Service Center will convert to Policy Notation, or not.']
    },

    {
        name: 'Convert Inactive Customers',
        tooltip: ['Indicates if Inactive customers should convert, or not.']
    },
    {
        name: 'Convert Account Comments to Sticky Note',
        tooltip: ['Indicates whether the comments convert as a "standard" comment in AMS360, or convert as a "sticky note."']
    },
    {
        name: 'Create Claim Total Adjustment Records',
        tooltip: ['Inserts data into the claims to ensure that the sum of the claims is equal to the specified total.',
            'This will only take effect if the sum of the claims does not equal the specified total.']
    },
    {
        name: 'Target Branch Mapped Value',
        tooltip: ['The value provided here will be applied to all Source Branch values as part of the conversion.',
            'Please note that this Branch will be blocked during the course of the conversion to prevent potential data corruption.',
            'This is a required value for no downtime conversions.']
    },
    {
        name: 'Target Division Mapped Value',
        tooltip: ['The value provided here will be applied to all Source Division values as part of the conversion.',
            'Please note that this Division will be blocked during the course of the conversion to prevent potential data corruption.',
            'This is a optional value for no downtime conversions.']
    },
    {
        name: 'Target Department Mapped Value',
        tooltip: ['The value provided here will be applied to all Source Department values as part of the conversion.',
            'Please note that this Department will be blocked during the course of the conversion to prevent potential data corruption.',
            'This is a optional value for no downtime conversions.']
    },
    {
        name: 'Target Group Mapped Value',
        tooltip: ['The value provided here will be applied to all Source Group values as part of the conversion.',
            'Please note that this Group will be blocked during the course of the conversion to prevent potential data corruption.',
            'This is a optional value for no downtime conversions.']
    },
]