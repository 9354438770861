import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, FormGroup, Input, Label, Button } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import _ from 'lodash';
import * as Clients from '../../client/ApiClient';
import { ValidationResultList, Validation } from 'components/basic/Form';


interface IClientEditPageProps extends RouteComponentProps<{ clientId?: string }> {
    isEditing: boolean;
};

interface IClientEditPageState {
    client: Clients.ConversionClientModel;
    validations: ValidationResultList;
}

export default class ClientEditPage extends React.Component<IClientEditPageProps, IClientEditPageState>{
    private readonly client: Clients.ClientClient = new Clients.ClientClient();

    private requiredFields = ['description', 'miniTargetHost', 'miniTargetCatalog'];

    constructor(props: IClientEditPageProps) {
        super(props)
        this.state = {
            client: new Clients.ConversionClientModel(),
            validations: new ValidationResultList()
        }
    }

    componentDidMount() {
        if (this.props.isEditing) {
            const id = this.props.match.params.clientId;

            this.client.get(id!)
                .then(response => {
                    this.setState({
                        client: response!
                    })
                })
                .catch(error => { });
        }
    }

    put = (client: Clients.ConversionClientModel) => {
        const id = this.props.match.params.clientId;

        return this.client.put(id!, this.state.client);
    }

    post = (client: Clients.ConversionClientModel) => {
        return this.client.post(client);
    }

    validate = (): ValidationResultList => {
        const result: ValidationResultList = new ValidationResultList();
        const client = this.state.client
        const requiredFields = this.requiredFields

        for (let i = 0; i < requiredFields.length; i++) {
            let property = requiredFields[i]
            let value = _.get(client, property)

            if (((property.startsWith('mini') && client.isMiniConvEnabled) || !property.startsWith('mini')) && (value === null || value === '' || value === undefined)) {
                result.add(property, { message: 'The field is required' })
            }
        }

        this.setState({
            validations: result
        })

        return result
    }

    handleMiniCheckboxChange = (event: { target: any; }) => {
        const checked = event.target.checked;
        const client = this.state.client;
        client.isMiniConvEnabled = checked;
        client.miniTargetHost = checked ? client.miniTargetHost : '';
        client.miniTargetCatalog = checked ? client.miniTargetCatalog : '';
        (document.getElementById('miniTargetHost') as HTMLInputElement).disabled = !checked;
        (document.getElementById('miniTargetHost') as HTMLInputElement).value = client.miniTargetHost!;
        (document.getElementById('miniTargetCatalog') as HTMLInputElement).disabled = !checked;
        (document.getElementById('miniTargetCatalog') as HTMLInputElement).value = client.miniTargetCatalog!;
        this.setState({ client: client });
    }

    handleNDTCheckboxChange = (event: { target: any; }) => {
        const checked = event.target.checked;
        const client = this.state.client;
        client.isNDTConvEnabled = checked;
        // TODO: add NDT target host and catalog

        this.setState({ client: client });
    }

    handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        const client = this.state.client;
        const validationRezult = this.validate();
        if (validationRezult.isValid()) {
            (this.props.isEditing ? this.put(client) : this.post(client))
                .then(() => {
                    this.props.history.push('/client');
                })
                .catch(() => { });
        }
    }

    public render() {
        return (
            <Container>
                <Form>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div>
                                {this.props.isEditing ?
                                    <h2>Edit Client</h2> :
                                    <h2>New Client</h2>
                                }
                                <h5>Please provide the following information:</h5>
                                <br />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 5, offset: 1 }}>
                            <FormGroup>
                                <Label for="description">Client Name</Label>
                                <Validation
                                    targetId='description'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={this.state.client.description || ''}
                                        maxLength={50}
                                        onChange={event => this.setState({
                                            client: Clients.ConversionClientModel.fromJS({
                                                ...this.state.client,
                                                description: event.target.value.replace(/[^A-Za-z0-9_ @./\\:#&+-]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                            <FormGroup>
                                <Label for="isNDTConvEnabled">Enabled for No Downtime Conversions</Label>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Input
                                    type="checkbox"
                                    name="isNDTConvEnabled"
                                    id="isNDTConvEnabled"
                                    checked={this.state.client.isNDTConvEnabled}
                                    onChange={e => this.handleNDTCheckboxChange(e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="isMiniConvEnabled">Enabled for Mini Conversions</Label>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Input
                                    type="checkbox"
                                    name="isMiniConvEnabled"
                                    id="isMiniConvEnabled"
                                    checked={this.state.client.isMiniConvEnabled}
                                    onChange={e => this.handleMiniCheckboxChange(e)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="miniTargetHost">Mini Conversion Host</Label>
                                <Validation
                                    targetId='miniTargetHost'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="text"
                                        name="miniTargetHost"
                                        id="miniTargetHost"
                                        value={this.state.client.miniTargetHost || ''}
                                        maxLength={50}
                                        disabled={!this.state.client.isMiniConvEnabled}
                                        onChange={event => this.setState({
                                            client: Clients.ConversionClientModel.fromJS({
                                                ...this.state.client,
                                                miniTargetHost: event.target.value.replace(/[^A-Za-z0-9_ @./\\:#&+-]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                            <FormGroup>
                                <Label for="miniTargetCatalog">Mini Conversion Catalog</Label>
                                <Validation
                                    targetId='miniTargetCatalog'
                                    validations={this.state.validations}
                                >
                                    <Input
                                        type="text"
                                        name="miniTargetCatalog"
                                        id="miniTargetCatalog"
                                        value={this.state.client.miniTargetCatalog || ''}
                                        maxLength={50}
                                        disabled={!this.state.client.isMiniConvEnabled}
                                        onChange={event => this.setState({
                                            client: Clients.ConversionClientModel.fromJS({
                                                ...this.state.client,
                                                miniTargetCatalog: event.target.value.replace(/[^A-Za-z0-9_ @./\\:#&+-]/gi, ''),
                                            })
                                        })}
                                    />
                                </Validation>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 5, offset: 1 }} className="action-col">
                            <Button className="submit-button-orange"
                                type="button"
                                onClick={this.handleSubmit}
                            >Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }
}