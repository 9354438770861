import * as React from 'react';
import * as Clients from '../../client/ApiClient';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

interface IUIControllerImageLoaderPageState {
    status: string;
    running: boolean;
    hubConnection: HubConnection | null;
}

class UIControllerImageLoaderPage extends React.Component<RouteComponentProps, IUIControllerImageLoaderPageState>{
    private readonly client: Clients.ConversionClient = new Clients.ConversionClient();

    constructor(props: Readonly<RouteComponentProps>) {
        super(props)
        this.state = {
            status: '',
            running: false,
            hubConnection: null,
        }
    }

    componentDidMount() {
        this.startService("")
        const link = '/executions';
        const hubConnection = new HubConnectionBuilder().withUrl(link).build();
        this.setState({ hubConnection }, () => {
            this.state.hubConnection!
                .start()
                .then(() => console.log("Connection started for Image Table"))
                .catch(() => console.log("there is an error"));
            this.state.hubConnection!.on("ImageLoaderStatus", (running) => {
                this.setState({ running })
            })
        })

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.state.hubConnection!.stop();
        this.setState = (_state, _callback) => {
            return;
        };
    }

    startService = (action: string) => {
        this.client.restartLoader(action)
            .then(response => {
                this.setState({ status: response! })
            })
            .catch(err => {
                console.log(err)
            })
    }


    public render() {
        return (
            <>
                <Card style={{
                    width: '16rem',
                    margin: '1%'
                }}>
                    <CardBody>
                        <CardTitle tag="h3">
                            Image Loader
                            <Badge color={this.state.status === "Running" ? "success" : "danger"}>
                                {this.state.status}
                            </Badge>
                            {this.state.running && <Badge color={"warning"}>
                                Processing Images
                            </Badge>}
                        </CardTitle>
                        <CardText>
                            <Button
                                disabled={this.state.running}
                                className='load-button'
                                onClick={() => this.startService('start')}
                            > {'Start Image Loader'} </Button>
                        </CardText>
                        <CardText>
                            <Button
                                disabled={this.state.running}
                                className='load-button'
                                onClick={() => this.startService('stop')}
                            > {'Stop Image Loader'} </Button>
                        </CardText>
                        <CardText>
                            <Button
                                disabled={this.state.running}
                                className='load-button'
                                onClick={() => this.startService('refresh')}
                            > {'Refresh Image Loader'} </Button>
                        </CardText>
                    </CardBody>
                </Card>
            </>
        )
    }
}

export default withRouter(UIControllerImageLoaderPage)